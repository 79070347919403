@use "common" as *;
@use "variables" as *;
@use "mixins" as *;

/* 16. Footer */
footer {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    overflow: hidden;
    & .common-shape-wrapper {
        background: #2b2d30;
        z-index: -1;
        & .common-shape-inner {
            background: $heading-color;
        }
    }
}
.widget-title {
    font-size: 20px;
    font-weight: 900;
    color: $white-color;
    margin-top: 12px;
}
.widget {
    & p {
        font-size: 15px;
        font-weight: 500;
        color: #a0a2a6;
        line-height: 2;
    }
    & ul {
        & li {
            list-style: none;
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0px;
            }
            a {
                font-size: 15px;
                font-weight: 500;
                color: #a0a2a6;
                line-height: 2;
                display: inline-block;
                position: relative;
                &:before {
                    position: absolute;
                    content: "";
                    height: 2px;
                    width: 0;
                    left: auto;
                    right: 0;
                    bottom: 3px;
                    background-color: $theme-color;
                    z-index: -1;
                    @include transition(0.3s);
                }
                &:hover {
                    color: $white-color;
                    &:before {
                        width: 100%;
                        left: 0px;
                        right: auto;
                    }
                }
            }
        }
    }
}
.footer-social {
    & a {
        font-size: 18px;
        color: $white-color;
        display: inline-block;
        margin-right: 35px;
        &:last-child {
            margin-right: 0px;
        }
        &:hover {
            color: $theme-color;
        }
    }
}
.widget-contact li {
    line-height: 26px;
    font-size: 15px;
    color: #a0a2a6;
    & img {
        margin-right: 8px;
        width: 18px;
    }
    & a {
        color: $white-color;
        display: block;
    }
}
.widget-newsletter {
    position: relative;
    & input {
        height: 60px;
        width: 100%;
        border: none;
        background: $white-color;
        font-size: 14px;
        color: $body-color;
        outline: none;
        padding: 0px 90px 0px 20px;
    }
    & button {
        height: 50px;
        border: none;
        background: $theme-color;
        font-size: 14px;
        color: $white-color;
        outline: none;
        padding: 0px 25px;
        cursor: pointer;
        display: inline-block;
        position: absolute;
        top: 5px;
        font-weight: 700;
        text-transform: uppercase;
        right: 5px;
        &:hover {
            background: $heading-color;
            color: $white-color;
        }
        @include mq(lg){
            position: inherit;
            display: block;
            margin-top: 10px;
            width: 100%;
        }
    }
}

.copyright-area {
    position: relative;
    z-index: 1;
    &:before {
        background: #1a1c1e;
        height: 100%;
        width: 400px;
        position: absolute;
        content: '';
        z-index: -1;
        top: 0px;
        left: 0px;
        @include mq(xs){
            width: 100%;
        }
    }
    & span {
        color: #a0a2a6;
        font-size: 15px;
        font-weight: 400;
        display: block;
        padding: 15px 0px;
        @include mq(xs){
            padding-bottom: 0px;
        }
    }
    & ul {
        & li {
            padding: 15px 0px;
            list-style: none;
            @include mq(xs) {
                padding-top: 5px;
            }
            & a {
                color: #a0a2a6;
                font-size: 15px;
                font-weight: 400;
                margin-right: 35px;
                position: relative;
                &:last-child {
                    margin-right: 60px;
                    @include mq(xs){
                        margin-right: 0px;
                    }
                    &:after {
                        display: none;
                    }
                }
                &:after {
                    position: absolute;
                    content: "";
                    font-size: 10px;
                    color: #a0a2a6;
                    font-family: $icon-font;
                    font-weight: 500;
                    right: -26px;
                    transform: rotate(66deg);
                    top: 2px;
                }
                &:hover {
                    color: $white-color;
                }
            }
        }
    }
}
.bs-widget-title.pl-15 {
    @include mq(lg){
        padding-left: 0px;
    }
}
.copyright-bg {
    background: #1a1c1e;
    padding: 28px 0px;
    @include mq(xs){
        padding: 12px 0px;
    }
}
.copyright {
    @include mq(xs){
        text-align: center;
    }
}
