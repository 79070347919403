$body-fonts: 'Roboto', sans-serif;
$icon-font: "Font Awesome 5 Pro";

$body-fz: 14px;
$body-color: #777a7e;
$heading-color: #242629;
$theme-color: #00affe;
$white-color: #fff;
$grey-color: #f2f3f5;
$dark-color: #242629;
$darker-color: #202125;
$border-color: #e9ebed;

