@use "common" as *;
@use "variables" as *;
@use "mixins" as *;

/* 19. Pricing */

.pricing-wrapper {
    background: $grey-color;
    border-radius: 8px;
    padding: 50px 20px 20px 20px;
    &:hover {
        & .pricing-icon {
            background: $theme-color;
            & span {
                color: $white-color;
            }
        }
    }
}
.pricing-icon {
    height: 120px;
    width: 120px;
    line-height: 120px;
    border-radius: 50%;
    background: transparentize($theme-color, 0.7);
    display: inline-block;
    @include transition(0.3s);
    vertical-align: middle;
    & span {
        font-size: 50px;
        color: $heading-color;
        @include transition(0.3s);
    }
}
.pricing-subtitle {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 0px;
}
.pricing-title {
    font-size: 60px;
    margin-bottom: 0px;
    @include mq(lg) {
        font-size: 50px;
    }
    @include mq(xs) {
        font-size: 50px;
    }
}
.pricing-list {
    display: inline-block;
    text-align: left;
    & ul {
        & li {
            font-size: 16px;
            color: $body-color;
            list-style: none;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0px;
            }
            & i {
                font-size: 20px;
                color: $theme-color;
                width: 25px;
                display: inline-block;
                transform: translateY(2px);
                &.cross-icon {
                    color: $body-color;
                }
            }
        }
    }
}
.pricing-list-bg {
    background: $white-color;
    border-radius: 8px;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
    padding: 35px 15px 40px 15px;
}
.pricing-btn {
    & .theme-btn {
        position: relative;
        display: inline-block;
        font-size: 18px;
        letter-spacing: .1em;
        z-index: 1;
        border-radius: 5px;
        @include mq(lg){
            padding: 0px 25px;
        }
        @include mq(md) {
            padding: 0px 35px;
        }
        @include mq(xs){
            padding: 0px 25px;
        }
         @include mq(sm) {
            padding: 0px 35px;
        }
        &:before {
            background:  $grey-color;
        }
    }
}
