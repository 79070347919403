@use "common" as *;
@use "variables" as *;
@use "mixins" as *;

/* 02. Header */
.transparent-header.sticky-header,
.transparent-header.sticky-header {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 999;
	background: $dark-color;
    animation: 800ms ease-in-out 0s normal none 1 running fadeInDown;
    @include transition(.3s);
}

.sticky-header2.sticky-header {
	background: $white-color !important;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
}
.transparent-header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 999;
    background-color: rgba(36, 38, 41, 0.102);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);
    & .header-space {
        padding: 0px 65px;
        @include mq(xxl){
            padding: 0px 0px;
        }
        @include mq(xl){
            padding: 0px 0px;
        }
        @include mq(lg){
            padding: 20px 0px;
        }
        @include mq(md){
            padding: 20px 0px;
        }
        @include mq(xs){
            padding: 20px 0px;
        }
    }
}


.main-menu {
    & ul {
        & li {
            display: inline-block;
            margin-right: 65px;
            position: relative;
            @include mq(xxl){
                 margin-right: 43px;
             }
             @include mq(xl){
                margin-right: 42px;
            }
            &:last-child {
                margin-right: 0px;
            }
            & a {
                font-size: 15px;
                display: inline-block;
                font-weight: 500;
                text-transform: uppercase;
                position: relative;
                color: $white-color;
                padding: 40px 0px;
            }
             /*submenu start*/
			& .sub-menu {
				background: $white-color none repeat scroll 0 0;
                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
                left: 0;
                opacity: 0;
                position: absolute;
                top: 130%;
                transition: all 0.3s ease 0s;
                visibility: hidden;
                width: 240px;
                z-index: 9;
                border-top: 3px solid $theme-color;
                text-align: left;
                padding: 15px 0;
				& li {
					display: block;
					margin: 0px;
                    padding: 8px 25px 8px 25px;
					& a {
                        padding: 0px;
                        display: inline-block;
                        color: #212237;
                        position: relative;
                        &:before {
                            content: "";
                            width: 0;
                            height: 2px;
                            bottom: 0;
                            position: absolute;
                            left: auto;
                            right: 0;
                            z-index: -1;
                            transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
                            background: $theme-color;
                        }
					}
                    &:hover {
                        & a {
                            color: $theme-color;
                            &:before {
                                width: 100%;
                                left: 0;
                                right: auto;
                            }
                        }
                    }
				}
			}
			/*submenu end*/
            &:hover {
                & a {
					color: $theme-color;
				}
                & .sub-menu {
					opacity: 1;
					visibility: visible;
					top: 100%;
				}
            }
        }
    }
}
.header-social {
    display: inline-block;
    position: relative;
    &:after {
        position: absolute;
        content: '';
        height: 50px;
        width: 1px;
        top: -9px;
        right: 0px;
        background: rgba(255, 255, 255,0.102);;
    }
    & a {
        font-size: 18px;
        color: $white-color;
        display: inline-block;
        margin-right: 35px;
        vertical-align: middle;
        @include mq(xxl){
            margin-right: 18px;
        }
        @include mq(xl){
            margin-right: 13px;
        }
        &:hover {
            color: $theme-color;
        }
    }
}
.header-search {
    display: inline-block;
    padding: 0px 28px;
    @include mq(xxl){
        padding: 0px 20px;
    }
    @include mq(xl){
        padding: 0px 15px;
    }
    & a {
        font-size: 18px;
        color: $white-color;
        display: inline-block;
        vertical-align: middle;
    }
}
.header-btn {
    display: inline-block;
}

//header style two start here
.header-top {
    padding-top: 9px;
}
.header-top-space {
    padding: 0px 65px 0px 65px;
    @include mq(xxl){
        padding: 0px 0px;
    }
    @include mq(xl){
        padding: 0px 0px;
    }
    @include mq(lg){
        padding: 0px 0px;
    }
    @include mq(md){
        padding: 0px 0px;
    }
    @include mq(xs){
        padding: 0px 0px;
    }
}
.welcome-text {
    & span {
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: $white-color;
        margin-bottom: 9px;
    }
}
.header-top-info {
    & ul {
        text-align: right;
        @include mq(md){
            text-align: center;
           }
        & li,
        & li a {
            display: inline-block;
            margin-right: 40px;
            list-style: none;
            font-size: 14px;
            font-weight: 500;
            color: $white-color;
            &:last-child {
                margin-right: 0px;
            }
            & img {
                width: 16px;
                margin-right: 6px;
                vertical-align: middle;
            }
        }
        & li {
            margin-bottom: 9px;
        }
    }
}

.header-area {
    position: inherit;
    background: $white-color;
}
.main-menu-2 {
    & ul {
        & li {
            & a {
                color: $body-color;
            }
            & .sub-menu {
                & li {
                    & a {
                        color: $body-color;
                    }
                }
            }
        }
    }
}
.header-social-2 {
    & a {
        color: $heading-color;
    }
    &:after {
        background: rgb(233, 235, 237);
    }
}
.header-search-2 {
    & a {
        color: $heading-color;
    }
}
//sidebar css
.side-menu-icon {
	position: relative;
}
.mobile-menu.mean-container {
	clear: both;
	overflow: hidden;
}
.contact-infos {
	margin-top: 30px;
    & h4 {
        font-size: 27px;
        color: $white-color;
        margin-bottom: 20px;
    }
    & ul {
        & li,
        & li a {
            font-size: 15px;
            color: $white-color;
            margin-bottom: 15px;
            list-style: none;
            &:last-child {
                margin-bottom: 0px;
            }
            & img {
                width: 16px;
                margin-right: 8px;
            }
        }
    }
}
.sidebar__menu--social {
	margin-top: 25px;
    & a {
        height: 40px;
        width: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        display: inline-block;
        margin-right: 5px;
        background: #393f53;
        color: $white-color;
        position: relative;
        z-index: 1;
        overflow: hidden;
        border-radius: 5px;
        &:before {
            position: absolute;
            content: "";
            top: 0px;
            left: 0px;
            height: 100%;
            width: 0%;
            background: $theme-color;
            z-index: -1;
            transform: rotate(90deg);
            @include transition(.3s);
        }
        & i {
            transform: rotate(0deg);
            @include transition(.3s);
        }
        &:hover {
            &:before {
                width: 100%;
            }
            & i {
                transform: rotate(360deg);
            }
        }
        &:last-child {
            margin-right: 0px;
        }
    }
}
.side-menu-icon i {
    color: $white-color;
}
.side-toggle2 i {
    color: $heading-color;
}
