@use "common" as *;
@use "variables" as *;
@use "mixins" as *;

.section-padding {
    padding-top: 120px;
    padding-bottom: 120px;
    @media screen and (max-width: 991px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media screen and (max-width: 768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}
.section-padding-small {
    padding-top: 80px;
    padding-bottom: 80px;
    @media screen and (max-width: 991px) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @media screen and (max-width: 768px) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.common-shape-wrapper {
    background: $grey-color;
    .common-shape-inner {
        background: $white-color;
    }
    @media screen and (min-width: 1200px) {
        width: 430px;
        height: 430px;
    }
}

.bg-theme-gray-lightest {
    background-color: transparentize($grey-color, 0.5);
    .common-shape-wrapper {
        background: $white-color;
        .common-shape-inner {
            background: transparentize($grey-color, 0.5);
        }
    }
}
.bg-theme-primary-light {
    background-color: transparentize($theme-color, 0.95);
    .common-shape-wrapper {
        background: $white-color;
        .common-shape-inner {
            background: transparentize($theme-color, 0.95);
        }
    }
}
.bg-theme-dark {
    background-color: $dark-color;
    .section-title {
        color: $white-color;
    }
    .common-shape-wrapper {
        background: $grey-color;
        .common-shape-inner {
            background: $dark-color;
        }
    }
    .provide-content {
        color: $grey-color;
        p, li {
            color: inherit;
        }
    }
}
.bg-theme-dark-light {
    background-color: transparentize($dark-color, 0.3);
    .section-title {
        color: $white-color;
    }
    .common-shape-wrapper {
        background: $grey-color;
        .common-shape-inner {
            background: transparentize($dark-color, 0.3);
        }
    }
    .provide-content {
        color: $white-color;
        p, li {
            color: inherit;
        }
    }
}

.grecaptcha-badge {
    z-index: 99;
}

.header-logo {
    max-width: 150px;
    max-height: 60px;
    overflow: hidden;
}

.footer-logo {
    max-width: 120px;
    max-height: 50px;
    overflow: hidden;
}

.kintro-img img {
    filter: none;
}

.main-menu ul li a {
    &.current {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 40px;
            z-index: -1;
            background: $white-color;
        }
    }
}

.side-info {
    background: $dark-color;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    height: 13px;
    width: 13px;
    transform: scale(1);
    &.swiper-pagination-bullet-active {
        background: $theme-color;
        transform: scale(1.1);
    }
}

.slider-section.slider-area {
    .single-slider {
        position: relative;
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.3;
            background-color: $dark-color;
            z-index: 1;
        }
        &.slider-height-2 {
            min-height: 915px;
            @media screen and (max-width: 991px) {
                min-height: 840px;
            }
            @media screen and (max-width: 767px) {
                min-height: 700px;
            }
            @media screen and (max-width: 575px) {
                min-height: 500px;
            }
        }
    }
}

.page-header-section {
    background-color: $dark-color;
    &.has-background {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0.3;
            background-color: $dark-color;
            z-index: 0;
        }
        .page-title-wrapper {
            position: relative;
            z-index: 1;
        }
    }
}

.contact-info-list {
    li {
        i {
            width: 20px;
            font-size: 18px;
            color: $theme-color;
        }
    }
}

.gallery-section.project-area {
    .project-button-prev.slide-prev, .project-button-next.slide-next {
        background: $grey-color;
        &:hover {
            background: $theme-color;
        }
    }
    .kproject img {
        filter: none;
    }
}

.partners-section.brand-area2 {
    .brand-wrapper img {
        opacity: 0.7;
        &:hover, &:focus, &:active {
            opacity: 1;
            filter: brightness(1.04);
        }
    }
}

.text-image-section {
    .provide-thumb {
        &::after {
            background: none;
            height: 0;
        }
        &::before {
            height: 10px;
            bottom: -15px;
        }
    }
}

footer {
    background-color: $dark-color;
    .copyright-area {
        background: $darker-color;
        &::before {
            display: none;
        }
        .copyright-bg {
            background: transparent;
        }
    }
}

