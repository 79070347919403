@use "common" as *;
@use "variables" as *;
@use "mixins" as *;

/* 17. Breadcrumb */
.breadcrumb-spacing {
    padding-top: 200px;
    padding-bottom: 155px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
     @include mq(xs){
        padding-top: 140px;
        padding-bottom: 60px;
    }
}
.page-title {
	color: $white-color;
	font-size: 50px;
	font-weight: 700;
    font-family: $body-fonts;
	line-height: 1;
    text-transform: uppercase;
}
.breadcrumb-menu {
	& li {
        display: inline-block;
        padding: 0 15px;
        position: relative;
        &:not(:last-child)::after {
            display: inline-block;
            color: $white-color;
            content: "\f101";
            position: absolute;
            right: -6px;
            top: 0px;
            font-family: "Font Awesome 5 Pro";
        }
        & span {
            color: $white-color;
            @include transition(0.3s);
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }
}
