@use "common" as *;
@use "variables" as *;
@use "mixins" as *;

/* 12. Blog */
.blog-area {
    background-repeat: no-repeat;
    background-position: bottom right;
    &.pt-120 {
        @include mq(md) {
            padding-top: 115px;
        }
        @include mq(xs) {
            padding-top: 115px;
        }
    }
}
.kblog-text.kblog-text2 {
	background: transparent;
	box-shadow: none;
	padding: 30px 0px 50px 0px;
    border-bottom: 1px solid $border-color;
    & p{
        font-size: 16px;
        line-height: 36px;
    }
}
.kblog-text {
    background: $white-color;
    padding: 30px 35px 30px 35px;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
    @include mq(xs){
        padding: 30px;
    }
    @include mq(sm){
        padding: 25px;
    }
    & .kblog-meta {
        margin-bottom: 5px;
        & a {
            font-size: 12px;
            font-weight: 500;
            color: $body-color;
            display: inline-block;
            position: relative;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-right: 30px;
            @include mq(sm){
                margin-right: 18px;
            }
            &:last-child {
                margin-right: 0px;
                &:after {
                    display: none;
                }
            }
            &:after {
                position: absolute;
                content: "";
                font-size: 8px;
                color: #a0a2a6;
                font-family: "Font Awesome 5 Pro";
                right: -22px;
                transform: rotate(66deg);
                top: 2px;
                font-weight: 900;
                @include mq(sm){
                    right: -16px;
                }
            }
            & i {
                color: $theme-color;
                font-size: 14px;
                margin-right: 2px;
            }
            &:hover {
                color: $theme-color;
            }
        }
    }
}
.kblog-text-title {
    font-size: 24px;
    line-height: 1.5;
    text-transform: uppercase;
    font-weight: 900;
    
    @include mq(lg){
        font-size: 20px; 
    }
    @include mq(md){
        font-size: 20px; 
    }
    @include mq(xs){
        font-size: 18px;
        line-height: 1.4;
    }
    @include mq(sm){
        font-size: 20px; 
        line-height: 1.4;
    }
    &:hover {
        color: $theme-color;
    }
}
.kblog-text-title2 {
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
    font-weight: 900;
    margin-top: 10px;
    @include mq(lg){
        font-size: 24px; 
    }
    @include mq(md){
        font-size: 24px; 
    }
    @include mq(xs){
        font-size: 20px; 
    }
    @include mq(sm){
        font-size: 24px; 
    }
    &:hover {
        color: $theme-color;
    }
}
.kblog-text-link {
    position: relative;
    padding-top: 15px;
    &:before {
        height: 1px;
        width: 90px;
        background: $border-color;
        position: absolute;
        content: '';
        top: 0px;
        left: 0px;
    }
    & a {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        color: $body-color;
        letter-spacing: 1.5px;
        & i {
            margin-left: 3px;
            @include transition(0.3s);
        }
        &:hover {
            color: $theme-color;
            & i {
                margin-left: 8px;
            }
        }
    }
}

.blog-space {
    margin-left: 360px;
    display: flex;
    border-bottom: 1px solid rgb(233, 235, 237);
    position: relative;
    @include mq(xxl){
        margin-left: 100px;   
    }
    @include mq(xl){
        margin-left: 15px;
    }
    @include mq(lg){
        margin-left: 15px;  
        margin-right: 15px;
        display: inherit;  
    }
    @include mq(md){
        margin-left: 15px;  
        display: inherit;  
        margin-right: 15px;
    }
    @include mq(xs){
        margin-left: 15px; 
        display: inherit;
        margin-right: 15px;   
    }
}
.blog-text {
    flex-basis: 26%;
    @include mq(xl){
        flex-basis: 35%;  
    }
    @include mq(lg){
        flex-basis: 100%; 
        padding-top: 0px;
        padding-bottom: 50px;
    }
    @include mq(md){
        padding-top: 0px;
        padding-bottom: 50px;
    }
    @include mq(xs){
        padding-top: 0px;
        padding-bottom: 50px;
    }
    & p {
        color: $body-color;
        font-size: 16px;
        line-height: 2;
        font-weight: 500;
    }
    & .section-title-wrapper {
        @include mq(xs){
            padding-right: 0px;
        }
    }
}
.kblog-img {
    position: relative;
    overflow: hidden;
    & span {
        position: absolute;
        right: 0px;
        bottom: 0px;
        padding: 10px 20px;
        font-size: 12px;
        font-weight: 700;
        color: $white-color;
        display: inline-block;
        background: $theme-color;
        text-transform: uppercase;
        letter-spacing: 1.5px;
    }
    & img {
        width: 100%;
        -webkit-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
    }
}
.blog-active {
    flex-basis: 74%;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
    @include mq(xl){
       flex-basis: 65%;   
    }
    @include mq(lg){
        flex-basis: 100%; 
    }
}

.blog-space .slide-prev, .blog-space .slide-next {
	position: static;
	font-size: 20px;
	background: $grey-color;
	height: 65px;
	width: 65px;
	line-height: 65px;
	color: $heading-color;;
	border-radius: 50%;
	border: none;
	outline: none;
	cursor: pointer;
	z-index: 3;
	text-align: center;
	margin: 0px;
	vertical-align: middle;
	@include transition(0.3s);
    display: inline-block;
    &:hover {
        background: $theme-color;
        color: $white-color;
    }
}
.blog-space .slide-next {
	margin-left: 10px;
}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
	opacity: 0.7;
}
.kblog:hover {
    & img {
        transform: scale3d(1.1, 1.1, 1.1);
        -webkit-filter: grayscale(0%);
        -ms-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
    }
}

.blog-2 {
    & .blog-active {
        box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
    }
}
.tag_cloud{
    & span{
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        color: $heading-color;
    }
    & .tag-cloud-link{
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        color: $body-color;
        margin-right: 3px;
        margin-bottom: 10px;
        display: inline-block;
        @include transition (.3s);
        &:hover{
            color: $theme-color;
        }
    }
}

.blog-social {
    & a {
        font-size: 18px;
        color: $body-color;
        display: inline-block;
        margin-left: 28px;
        vertical-align: middle;
        @include mq(xxl){
            margin-right: 18px;   
        }
        @include mq(xl){
            margin-right: 13px;   
        }
        @include mq(xs){
            margin-right: 13px;
            margin-left: 0;
        }
        &:hover {
            color: $theme-color;
        }
    }
}
.blog-author{
    padding: 60px 60px 55px 60px;
    @include mq(lg){
        padding: 40px 40px 35px 40px;
    }
    @include mq(md){
        padding: 60px 60px 60px 60px;
    }
    @include mq(xs){
        padding: 30px 30px 20px 30px;
    }
    & .blog-author-img {
        margin-right: 40px;
    }
    & .blog-author-text{
        & h4{
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 35px;
            @include mq(lg){
                margin-bottom: 15px;
            }
            @include mq(md){
                margin-bottom: 15px;
            }
            @include mq(xl){
                margin-bottom: 10px;
            }
            @include mq(lg){
                margin-bottom: 10px;
            }
        }
        & p{
            font-size: 16px;
            line-height: 34px;
            @include mq(lg){
                font-size: 14px;
                line-height: 30px;
            }
            @include mq(md){
                font-size: 15px;
                line-height: 30px;
            }
        }
    }
}

.blog-comment-box{
    padding: 55px 0px;
    & .blog-single-comment{
        position: relative;
        border-bottom: 1px solid $border-color;
        padding: 60px 40px 55px 0px;
        @include mq(lg){
            padding: 60px 40px 40px 0px;
        }
        @include mq(xs){
            padding: 60px 40px 40px 10px;
        }
        & .blog-comment-img {
            width: 440px;
            @include mq(lg){
                margin-right: 20px;
                width: 540px;
            }
            @include mq(md){
                margin-right: 20px;
            }
        }
        & .blog-comment-text{
            padding: 10px 0px;
            & h4{
                font-size: 20px;
                text-transform: uppercase;
                font-weight: 700;
                margin-bottom: 25px;
            }
            & p{
                font-size: 16px;
                line-height: 34px;
            }
        }
    }
}
.blog-single-comment.no-pt {
    @include mq(xs) {
        padding-top: 30px;
    }
}
.comment-title{
    padding: 0;
    margin-bottom: -8px;
}
.comment-title2{
    padding: 0;
    margin-bottom: 52px;
}
.comment-box-title{
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
}
.reply-btn {
	position: absolute;
	top: 70px;
	right: 0;
    @include mq(xs){
        top: 80px;
        position: static;
        margin-top: 15px;
    }
}
.comment-btn {
	font-size: 12px;
	text-transform: uppercase;
	padding: 15px 20px;
	background: $theme-color;
	color: $white-color;
	letter-spacing: 1px;
    @include transition (.3s);
    &:hover{
        background-color: #222;
        color: $white-color;
    }
}
.blog-comment-form {
    & input, textarea {
        width: 100%;
        padding: 25px;
        color: $body-color;
        border: none;
        background: $grey-color;
        font-size: 14px;
        border: 1px solid transparent;
        @include transition (.3s);
        @include mq(xs) {
            padding: 15px;
        }
        &:focus{
            border-color: $theme-color;
        }
    }
}
.sidebar-search-from {
    position: relative;
    & form{
        & input{
            color: $white-color;
            font-size: 16px;
            background: $theme-color;
            position: relative;
            width: 100%;
            height: 84px;
            border: 0;
            padding: 0 50px;
            &::-moz-placeholder {
                color: $white-color;
            }
            &::placeholder {
                color: $white-color;
            }
        }
        & button{
            position: absolute;
            top: 0;
            height: 100%;
            padding: 0px;
            color: $white-color;
            line-height: 60px;
            border: 0;
            border-radius: 50px;
            font-size: 16px;
            right: 35px;
            background: none;
            & i{
                font-size: 18px;
            }
        }
    }
}
.widget_-latest-posts {
	padding: 45px 50px 35px 50px;
	background: $grey-color;
    @include mq(lg){
        padding: 45px 40px 35px 40px;
    }
    @include mq(md){
        padding: 45px 50px 35px 50px;
    }
    @include mq(xs){
        padding: 30px 25px 30px 25px;
    }
    @include mq(sm){
        padding: 45px 40px 50px 40px;
    }
}
.rc-post{
    & .rc-thumb{
        margin-right: 15px;
        width: 100px;
        & img {
               width: 100%; 
            }
        @include mq(xs){
            width: 70px;
            width: 100px;
        }
    }
    & .rc-text{
        & .kblog-meta{
            & a{
                font-size: 12px;
                font-weight: 500;
                color: $body-color;
                display: inline-block;
                position: relative;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin-right: 30px;
                margin-bottom: 6px;
                & i{
                    color: $theme-color;
                    font-size: 14px;
                    margin-right: 2px;
                }
                &:hover{
                    color: $theme-color;
                }
            }
        }
        & h5{
            font-size: 16px;
            line-height: 26px;
            font-weight: 700;
            text-transform: uppercase;
            @include transition(.3s);
            &:hover{
                color: $theme-color;
            }
            @include mq(xl){
                font-size: 14px;
            }
            @include mq(lg){
                font-size: 14px;
                line-height: 20px;
            }
            @include mq(md){
                font-size: 14px;
                line-height: 22px;
            }
            @include mq(xs){
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}
.widget_tag_cloud{
    background: $grey-color;
    padding: 45px 50px 45px 50px;
    @include mq(lg){
        padding: 35px 40px 35px 40px;
    }
    @include mq(xs){
        padding: 30px 25px 30px 25px;
    }
}
.tagcloud {
    & a{
        background: $white-color;
        color: #6B727E;
        padding: 6px 15px;
        display: inline-block;
        font-size: 14px !important;
        margin-bottom: 5px;
        margin-right: 2px;
        text-transform: capitalize;
        &:hover{
            background:$theme-color;
            color: $white-color;
        }
    }
}


//pagination css
.basic-pagination ul li {
	display: inline-block;
	margin-right: 10px;
    & a,
    & span {
       position: relative;
        overflow: hidden;
        background: #f1f1f1;
        color: $heading-color;
        font-size: 20px;
        display: inline-block;
        width: 44px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px; 
    }
}
.basic-pagination ul li a:hover, .basic-pagination ul li span.current {
	background: $theme-color;
	color: #ffffff;
}